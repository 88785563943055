import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import Layout from "../../../components/layout"
import {
  faLink,
  faPhone,
  faPlay,
  faSmile,
  faPaperPlane,
  faCircleNotch,
  faChevronRight, faEnvelope,
} from "@fortawesome/free-solid-svg-icons"
import * as PropTypes from "prop-types"

class RatingModel extends React.Component {
  constructor(props) {
    super(props)
    this.state = { rating: -1, usertext: "" }
  }

  setRating(rating = this.state.rating) {
    this.setState({ rating }, () => this.props.setRating(this.props.question, this.state))
  }

  render() {
    return <div style={{ background: "#eee", borderRadius: "8px", padding: "20px", margin: "50px auto" }}>
      <p style={{ paddingTop: 20 }}>{this.props.question.question}</p>
      {
        this.props.question.type === "number" &&
        <div style={{ width: "fit-content", margin: "30px auto" }}>
          <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
            {
              [1, 2, 3, 4, 5].map(rating => <a key={"rating" + rating}
                                               className={"btn btn-primary-outline " + (rating === this.state.rating ? " active" : "")}
                                               onClick={() => this.setRating(rating)}>{rating}</a>)
            }
          </div>
          <div style={{ margin: "10px 0", display: "flex", justifyContent: "space-between" }}>
            <div>{this.props.question.term1}</div>
            <div>{this.props.question.term5}</div>
          </div>
        </div>
      }
      {
        (this.props.question.type === "usertext" ||
          this.props.question.usertext?.condition.includes(this.state.rating)) &&
        <textarea placeholder={this.props.question.usertext?.question ?? "Hier können Sie einen Text eingeben"}
                  className={"bigform"}
                  onKeyUp={e => {
                    this.state.usertext = e.target.value
                    this.setRating()
                  }}>{this.state.usertext}</textarea>
      }
      {
        (this.props.question.info?.condition.includes(this.state.rating)) &&
        <div className={"alert alert-info"} style={{ marginTop: 50 }}><h2 style={{ marginTop: 0, marginBottom: 10 }}>
          <FontAwesomeIcon icon={faSmile} /> Wussten Sie schon?</h2> {this.props.question.info.text}</div>
      }
    </div>
  }
}

const queryParams = new URLSearchParams((typeof window !== "undefined") ? window.location.search : null)

const questions = [
  {
    slug: "Technische Details",
    question: "Ich verstehe immer wenn mir technische Details genannt werden.",
    term1: "Nie",
    term5: "Immer",
    type: "number",
  },
  {
    slug: "Technische Details verstehen",
    question: "Ich möchte diese technischen Zusammenhänge verstehen.",
    term1: "Gar nicht",
    term5: "Unbedingt",
    type: "number",
  },
  {
    slug: "Zusätzliche Services",
    question: "Ich hätte mir Zusätzliche Services gewünscht.",
    term1: "Nein",
    term5: "Ja",
    type: "number",
    usertext: {
      condition: [3, 4, 5],
      question: "Folgende Services würde ich mir zusätzlich wünschen",
    },
  },
  {
    slug: "Preise angemessen",
    question: "Ich verstehe wie Preise sich zusammensetzen und finde diese angemessen.",
    term1: "Nein",
    term5: "Ja",
    type: "number",
  },
  {
    slug: "CarePlus Angebot",
    question: "Ich finde das CarePlus Angebot sehr gut.",
    term1: "Nicht gut",
    term5: "Sehr gut",
    type: "number",
    usertext: {
      condition: [1, 2, 3, 4],
      question: "Folgendes fehlt mir bei den CarePlus Angeboten",
    },
  },
  {
    slug: "Webseite",
    question: "Die Webseite www.ziegenhagel.com hat hilfreiche Informationen für mich und ich besuche sie regelmäßig.",
    term1: "Niemals",
    term5: "Sehr Häufig",
    type: "number",
    usertext: {
      condition: [1, 2, 3, 4],
      question: "Folgendes vermisse ich auf der Seite",
    },
  },
  {
    slug: "Weiterempfehlen",
    question: "Ich habe die angebotenen Services bereits an andere Personen weiterempfohlen.",
    term1: "Niemals",
    term5: "Sehr Häufig",
    type: "number",
    info: {
      condition: [1, 2, 3, 4],
      text: "Sie können einen Rabatt von 50€ auf Ihre nächste Rechnung erhalten, wenn durch Ihre Weiterempfehlung ein Projekt zustande kommt. Das gilt auch für CarePlus Pläne.",
    },
  },
  {
    slug: "Vorstellungen erfüllt",
    question: "Das Projekt Ergebnis entspricht meinen ursprünglichen Vorstellungen.",
    term1: "Gar nicht",
    term5: "Vollständig",
    type: "number",
    usertext: {
      condition: [1, 2, 3, 4],
      question: "Folgendes kann verbessert werden",
    },
  },
  {
    slug: "Mehr Surveys",
    question: "Ich möchte auch in Zukunft die Möglichkeit haben solche Projekte zu bewerten.",
    term1: "Bitte nicht",
    term5: "Unbedingt",
    type: "number",
  },
  {
    slug: "Frustration",
    question: "Es gab einen Punkt an dem ich frustriert war",
    term1: "Nein",
    term5: "Merhmals",
    type: "number",
    usertext: {
      condition: [2, 3, 4, 5],
      question: "Folgendes hat mich frustriert",
    },
  },
  {
    slug: "Anmerkungen",
    question: "Das möchte ich noch anmerken:",
    type: "usertext",
  },
]

export default class SurveyProject extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      step: 0,
      success: false,
      loading: false,
      iat: Date.now(),
      ratings: [],
      alreadyRated: false,
    }
  }

  submit() {
    this.callAPI()
  }

  setRating(question, rating) {
    this.setState({
      ratings: {
        ...this.state.ratings,
        [question.slug]: rating,
      },
    })
  }

  componentDidMount() {

    // check if user has already rated
    fetch("https://api.ziegenhagel.com/noauth/" + queryParams.get("pid") + "/survey-project/", {
      method: "GET",
    }).then(response => response.json())
      .then(result => {
        this.setState({ alreadyRated: result.num_rows > 0 })
      })
      .catch(error => {
        console.log(error)
      })
  }

  callAPI() {

    this.setState({ loading: true })
    fetch("https://api.ziegenhagel.com/noauth/" + queryParams.get("pid") + "/survey-project/", {
      method: "post",
      body: JSON.stringify({
        mins: (Date.now() - this.state.iat) / 60000,
        data: this.state.ratings,
        data_structure: questions,
      }),
    }).then(d => d.json()).then(d => {
      this.setState({ success: d.success, loading: false, step: 2 })
      console.log("api survey", d)
    }).catch(e => {
      console.log("api survey error", e)
      window.alert("Es ist ein Fehler aufgetreten. Bitte versuchen Sie es später erneut.")
      this.setState({ loading: false })
    })
  }


  render() {
    if (this.state.alreadyRated)
      return <Layout container={true}>
        <div className={"text-center mb-10"} style={{ width: "600px", margin: "auto" }}>
          <h1
            className="post-heading text-center">{this.state.step === 2 ? "Danke für Ihre Bewertung!" : "Feedback zum Projekt"}</h1>
          <p className={"text-muted"}  style={{ opacity: .5 }}>Dieser Fragebogen kann pro Projekt nur einmal ausgefüllt werden. Sie haben bereits eine Bewertung abgegeben.</p>
          <a href={"mailto:info@ziegenhagel.com?subject=Weiteres Feedback"} className={"btn btn-primary-outline"}><FontAwesomeIcon icon={faEnvelope}/> Schreiben Sie uns</a>
        </div>
      </Layout>
    else
      return (<Layout container={true}>
          <div className={"text-center mb-10"} style={{ width: "600px", margin: "auto" }}>
            <div className={this.state.loading ? "pending" : ""}>
              <h1
                className="post-heading text-center">{this.state.step === 2 ? "Danke für Ihre Bewertung!" : "Feedback zum Projekt"}</h1>
              {
                this.state.step === 0 && <>
                  <p>Um den Service für Sie stetig zu verbessern, ist
                    regelmäßiges Feedback
                    notwending.
                    <br /><br />Da wir Ihre Zeit sehr schätzen, und Sie bitten möchten, die Fragen in Ruhe zu
                    beantworten,
                    ziehen wir die von Ihnen auf diesem Fragebogen verbrachte Zeit (max. 15 Minuten) von Ihrer nächsten
                    Rechnung ab.</p>
                  <div className={"btn btn-primary-outline"} onClick={() => this.setState({ step: 1 })}>Fragebogen
                    starten <FontAwesomeIcon icon={faChevronRight} /></div>
                </>
              }
              {
                this.state.step === 1 &&
                <div>
                  {
                    questions.map(question => <div style={{ margin: "20px 0" }}>
                      <RatingModel setRating={this.setRating.bind(this)} question={question}></RatingModel>
                    </div>)
                  }
                  <button onClick={this.submit.bind(this)} className={"bigform"}> Absenden <FontAwesomeIcon
                    icon={faPaperPlane}></FontAwesomeIcon>
                  </button>
                  {this.state.loading &&
                    <div><FontAwesomeIcon icon={faCircleNotch} className={"fa-spin"} /> Senden ...</div>}
                </div>
              }
              {
                this.state.step === 2 && <>
                  <p>Ihnen werden {Math.ceil((Date.now() - this.state.iat) / 60000) > 15 ? 15 : Math.ceil((Date.now() - this.state.iat) / 60000)} Minuten
                    auf Ihrer nächsten Rechnung gutgeschrieben.</p>
                  <p style={{opacity:.5}}>Sie können diese Seite jetzt schliessen.</p>
                </>
              }
            </div>
          </div>
        </Layout>
      )
  }
}
